import React, {useState, useEffect, useCallback } from 'react';
import {
  Card,
  Button,
  Icon,
  Layout,
  Tag,
  TextField,
  Form,
  Page as OldPage,
  FormLayout,
  Spinner,
  Banner,
  OptionList,
  ColorPicker,
  Select,
  DropZone,
  TextContainer,
} from 'admin-frontend';
import {  MultiTableForm, MultiTable, rearrangeArray, Auth, useRedirect, Stack } from "admin-frontend";
import { useSpotFetch } from "../useSpotFetch";
import { DeleteMajor, RefreshMajor } from 'admin-frontend';
import { Page } from "../components/Page"
import { localeFormat, LocalizedTextField, LocalizedBadgeList } from "../components/Localization";
import { ResourcePickerButton } from "../components/ResourcePicker";
import { ProductTile } from "../components/Product";
import { SpotState } from "../components/API";
import { RulesetSidebar } from "../components/RulesetSidebar";
import { ConditionTable, translateRules } from "./GlobalProperties/Ruleset";
import { SwatchSelector, SwatchText } from "./Swatches";
import { useParams } from "react-router-dom";


export function Badges() {
  const { badgeId, badgeGroupId } = useParams();
  const redirect = useRedirect();
  const [badges, setBadges] = useState(null);
  const [badgeGroups, setBadgeGroups] = useState(null);
  const [badge, setBadge] = useState(null);
  const [badgeGroup, setBadgeGroup] = useState(null);
  const [swatches, setSwatches] = useState(null);
  const [rulesets, setRulesets] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewProduct, setPreviewProduct] = useState(null);

  const [remainingBadgeId, setRemainingBadgeId] = useState(null);

  const authFetch = useSpotFetch();

  const shouldResetBadgeGroup = badgeGroupId && (!badgeGroup || (badgeGroupId != "new" && badgeGroup.id != badgeGroupId) || (!badgeGroup.id && badgeGroupId != "new"));
  const shouldResetBadge = badgeId && (!badge || (badgeId != "new" && badge.id != badgeId) || (!badge.id && badgeId != "new"));

  useEffect(() => {
    if (!badgeGroups)
      authFetch("/api/badges/groups").then((r) => setBadgeGroups(r.groups));
  }, [badgeGroups]);

  useEffect(() => {
    if (!badges)
      authFetch("/api/badges").then((r) => setBadges(r.badges));
  }, [badges]);

  useEffect(() => {
    if (!swatches)
      authFetch("/api/swatches").then((r) => setSwatches(r.swatches));
  }, [badges]);

  useEffect(() => {
    if (!rulesets)
      authFetch("/api/global/rulesets").then((r) => setRulesets(r.rulesets));
  }, [rulesets]);

  useEffect(() => {
    if (shouldResetBadge && badges)
      setBadge(!badgeId ? null : ((badgeId == "new") ? {} : badges.filter((b) => b.id == badgeId)[0]));
  }, [badgeId, shouldResetBadge, badges]);
  useEffect(() => {
    if (shouldResetBadgeGroup && badgeGroups)
      setBadgeGroup(!badgeGroupId ? null : ((badgeGroupId == "new") ? {} : badgeGroups.filter((b) => b.id == badgeGroupId)[0]));
  }, [badgeGroupId, shouldResetBadge, badgeGroups]);

  const badgeHash = badges && Object.fromEntries(badges.map((b) => [b.id, b]));
  const swatchHash = swatches && Object.fromEntries(swatches.map((s) => [s.id, s]));

  const spotInit = useCallback((spotAPI, spotDOM, spotDefault) => {
    if (badgeGroup && swatches && badges) {
      spotDOM.badges((badgeGroup.badge_ids || []).map((id) => new spotDOM.Badge({ ...badgeHash[id], conditions: translateRules(badgeHash[id].conditions) }, badgeHash[id].swatch_id ? swatchHash[badgeHash[id].swatch_id] : null)));
    }
  }, [badgeGroup, badges, swatches]);

  if (shouldResetBadge || shouldResetBadgeGroup || !badges || !badgeGroups || !rulesets)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);

  const rulesetHash = rulesets && Object.fromEntries(rulesets.map((r) => [r.id, r]));
  const badgeGroupHash = Object.fromEntries(badgeGroups.map((b) => [b.id, b]));

  if (badgeGroup) {
    return (<Page
      title="Edit Badge Group"
      subtitle={localeFormat(null, badgeGroup.name)}
      onBack={() => { redirect('/merchandising/badges'); }}
      isLoading={isLoading}
      isChanged={isChanged}
      onSave={() => {
        setIsLoading(true);
        authFetch('/api/badges/groups', { json: badgeGroup }).then((r) => {
          setIsLoading(false);
          setIsChanged(false);
          setBadgeGroups(badgeGroup.id ? badgeGroups.map((b) => b.id == r.id ? r : b) : [...badgeGroups, r]);
          redirect('/merchandising/badges');
        });
      }}
    >
      {((currentTheme, currentLocale) => {
        const validBadges = badges;
        const remainingBadges = badges.filter((b) => (badgeGroup.badge_ids || []).filter((id) => id == b.id).length == 0);
        return (<Layout>
          <Layout.Section>
            <Card>
              <Card.Section title="Metadata">
                <FormLayout>
                  <LocalizedTextField
                    label="Name"
                    locale={currentLocale}
                    value={badgeGroup.name}
                    onChange={(val) => {  setIsChanged(true); setBadgeGroup({ ...badgeGroup, name: val }); }}
                  />
                  <TextField
                    label="Description"
                    multiline={4}
                    value={badgeGroup.description || ''}
                    placeholder="Optional freeform description for this badge. Does not affect functionality."
                    onChange={(val) => {  setIsChanged(true); setBadgeGroup({ ...badgeGroup, description: val }); }}
                  />
                  <Select
                    label="Display"
                    value={badgeGroup.display || "first"}
                    options={[
                      { label: "Display the first relevant badge", value: "first" },
                      { label: "Display all relevant badges", value: "all" }
                    ]}
                    onChange={(val) => { setIsChanged(true); setBadgeGroup({ ...badgeGroup, display: val }); }}
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title="Badges">
                {validBadges && validBadges.length > 0 && (badgeGroup.badge_ids || []).length < validBadges.length && <Stack>
                  <Select
                    options={remainingBadges.map((badge, idx) => { return { label: localeFormat(currentLocale, badge.name), value: '' + badge.id } })}
                    onChange={(val) => { setRemainingBadgeId(parseInt(val)); }}
                    value={(remainingBadgeId || 0) + ""}
                  />
                  <Button
                    primary
                    onClick={() => {
                      setBadgeGroup({ ...badgeGroup, badge_ids: [...(badgeGroup.badge_ids || []), (remainingBadgeId || remainingBadges[0].id)] })
                      setRemainingBadgeId(null)
                      setIsChanged(true);
                    }}
                  >
                    Add
                  </Button>
                </Stack>}
                <MultiTable
                  bulkActions={[
                    {
                      content: "Remove",
                      onAction: (items) => {
                        setIsChanged(true);
                        if (typeof items !== "object") items = [items];
                        setBadgeGroup({
                          ...badgeGroup,
                          badge_ids: badgeGroup.badge_ids.filter((fv, idx) => !items.includes(idx))
                        });
                        setIsChanged(true);
                      },
                    },
                  ]}
                  onDragDrop={(source, destination) => {
                    setBadgeGroup({ ...badgeGroup, badge_ids: rearrangeArray(badgeGroup.badge_ids, source, destination) });
                    setIsChanged(true);
                  }}
                  resourceName={{
                    singular: "badge",
                    plural: "badges"
                  }}
                  headings={[
                    "Badge"
                  ]}
                  rows={badgeGroup && (badgeGroup.badge_ids || []).map((id, idx) => {
                    const badge = badges.filter((b) => b.id == id)[0];
                    return [localeFormat(currentLocale, badge.name)]
                  })}
                />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <RulesetSidebar
              rulesets={rulesets}
              resourceName={{ singular: "badge group", plural: "badge groups" }}
              selected={badgeGroup.ruleset_ids || rulesets.filter((r) => r.badge_group_id == badgeGroup.id).map((r) => r.id)}
              getElement={(ruleset) => { return badgeGroupHash[ruleset.badge_group_id]; } }
              element={badgeGroup}
              setSelected={(val) => { setIsChanged(true); setBadgeGroup({ ...badgeGroup, ruleset_ids: val.map((v) => parseInt(v)) }); }}
            />
            <Card title="Preview">
              <Card.Section>
                <ResourcePickerButton onSelection={((p) => { setPreviewProduct(p.selection[0]); })}/>
              </Card.Section>
              {previewProduct && <Card.Section><SpotState init={spotInit}><ProductTile product={previewProduct}/></SpotState></Card.Section>}
            </Card>
          </Layout.Section>
        </Layout>);
      })}
    </Page>);
  }
  if (badge) {
    return (<Page
      title={!badge.id ? "Add Badge" : "Edit Badge"}
      subtitle={localeFormat(null, badge.name)}
      onBack={() => { redirect('/merchandising/badges'); }}
      isLoading={isLoading}
      isChanged={isChanged}
      onSave={() => {
        setIsLoading(true);
        authFetch('/api/badges', { json: badge }).then((r) => {
          setIsLoading(false);
          setBadges(badge.id ? badges.map((b) => b.id == r.id ? r : b) : [...badges, r]);
          setIsChanged(false);
          redirect('/merchandising/badges');
        });
      }}
    >
      {((currentTheme, currentLocale) => {
        return (<Layout>
          <Layout.Section>
            <Card>
              <Card.Section title="Metadata">
                <FormLayout>
                  <LocalizedTextField
                    label="Name"
                    locale={currentLocale}
                    value={badge.name}
                    onChange={(val) => {  setIsChanged(true); setBadge({ ...badge, name: val }); }}
                  />
                  <TextField
                    label="Description"
                    multiline={4}
                    value={badge.description || ''}
                    placeholder="Optional freeform description for this badge. Does not affect functionality."
                    onChange={(val) => {  setIsChanged(true); setBadge({ ...badge, description: val }); }}
                  />
                  <SwatchSelector
                    label="Swatch"
                    fullWidth
                    swatches={swatches}
                    setSwatches={setSwatches}
                    value={badge.swatch_id}
                    onChange={(val) => {  setIsChanged(true); setBadge({ ...badge, swatch_id: val }); }}
                  />
                  <TextField
                    label="Class"
                    placeholder="Space separated class list to apply on the frontend."
                    value={(badge.class)}
                    onChange={(val) => { setIsChanged(true); setBadge({ ...badge, class: val }); }}
                  />
                  <Select
                    label="Position"
                    value={badge.position || "default"}
                    options={[
                      { label: "Default", value: "default" },
                      { label: "Top", value: "top" },
                      { label: "Top Left", value: "top-left" },
                      { label: "Top Right", value: "top-right" },
                      { label: "Bottom", value: "bottom" },
                      { label: "Bottom Right", value: "bottom-right" },
                      { label: "Bottom Left", value: "bottom-left" },
                    ]}
                    onChange={(val) => {  setIsChanged(true); setBadge({ ...badge, position: (val != "default" ? val : null)  }); }}
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title="Conditions">
                <ConditionTable rules={badge.conditions || []} specifiers={{ publicOnly: true }} setRules={(conditions) => { setIsChanged(true); setBadge({ ...badge, conditions: conditions }); }}/>
                <Button primary fullWidth onClick={() => {
                  setIsChanged(true);
                  setBadge({ ...badge, conditions: [...(badge.conditions || []), {
                    column: ["title"],
                    relation: "contains",
                    condition: ""
                  }] });
                }}>Add a Condition</Button>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            {badgeGroups && badgeGroups.length > 0 && <Card
              title='Groups'
              sectioned
              >
                <OptionList
                  selected={(badge.badge_group_ids || []).map((id) => id+"")}
                  onChange={(val) => { setIsChanged(true); setBadge({ ...badge, badge_group_ids: val.map((id) => parseInt(id)) }); } }
                  options={badgeGroups.map((group, idx1) => { return { label: localeFormat(currentLocale, group.name), value: group.id + ""} })}
                  allowMultiple
                />
              </Card>
            }
          </Layout.Section>
        </Layout>);
      })}
    </Page>);
  }
  return (<Page>
    {((currentTheme, currentLocale) => {
      return (<>
        <MultiTableForm
          loading={!badges}
          resourceName={{ singular: "badge", plural: "badges" }}
          onNew={() => { redirect('/merchandising/badges/new') }}
          headings={["Name", "Swatch", "Applied Groups"]}
          onRowClick={(row, idx) => { redirect('/merchandising/badges/' + badges[idx].id); }}
          rows={badges && badges.map((b) => [
            localeFormat(currentLocale, b.name),
            (b.swatch_id && swatchHash[b.swatch_id] ? (<SwatchText swatch={swatchHash[b.swatch_id]}>{swatchHash[b.swatch_id].name[0] ? swatchHash[b.swatch_id].name[0] : "Unnamed"}</SwatchText>) : "None"),
            <LocalizedBadgeList maxToDisplay={3} locale={currentLocale} values={b.badge_group_ids.map((id) => badgeGroupHash[id] ? badgeGroupHash[id].name : "?")}/>,
          ])}
        />
        <MultiTableForm
          loading={!badgeGroups}
          resourceName={{ singular: "badge group", plural: "badge groups" }}
          onNew={() => { redirect('/merchandising/badges/groups/new') }}
          headings={["Name", "Badges", "Applied Locations"]}
          onRowClick={(row, idx) => { redirect('/merchandising/badges/groups/' + badgeGroups[idx].id); }}
          rows={badgeGroups && badgeGroups.map((b) => [
            localeFormat(currentLocale, b.name),
            <LocalizedBadgeList maxToDisplay={3} locale={currentLocale} values={b.badge_ids.map((id) => badgeHash[id] ? badgeHash[id].name : "?")}/>,
            <LocalizedBadgeList maxToDisplay={3} locale={currentLocale} values={rulesets.filter((r) => r.badge_group_id == b.id).map((r) => r.handle)}/>
          ])}
        />
      </>);
    })}
  </Page>);
}

